<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('dealer_panel.fertilizer_requisition_report') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="fiscal_year_id"
            >
              <template v-slot:label>
                {{$t('warehouse_config.fiscal_year')}}
              </template>
              <b-form-select
                  plain
                  v-model="search.fiscal_year_id"
                  :options="fiscalYearList"
                  id="fiscal_year_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="month"
            >
              <template v-slot:label>
                {{$t('pusti_mapping.month')}}
              </template>
              <b-form-select
                  plain
                  v-model="search.month"
                  :options="monthList"
                  id="month"
              >
                <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="allocation_type_id"
            >
              <template v-slot:label>
                {{$t('fertilizerSales.allocationType')}}
              </template>
              <b-form-select
                  plain
                  v-model="search.allocation_type_id"
                  :options="allocationTypeList"
                  id="allocation_type_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
           <b-col xs="12" sm="12" md="6" lg="6" xl="6">
<!--              <b-form-group-->
<!--                  class="row"-->
<!--                  label-cols-sm="4"-->
<!--                  :label="$t('fertilizerConfig.fertilizer_name')"-->
<!--                  label-for="fertilizer_id"-->
<!--                  >-->
<!--                  <b-form-select-->
<!--                      plain-->
<!--                      v-model="search.fertilizer_id"-->
<!--                      :options="fertilizerNameList"-->
<!--                      id="fertilizer_id"-->
<!--                      >-->
<!--                      <template v-slot:first>-->
<!--                      <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>-->
<!--                      </template>-->
<!--                  </b-form-select>-->
<!--              </b-form-group>-->
          </b-col>
          <b-col  xs="12" sm="12" md="6" lg="6" xl="6">
            <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="from_date"
            >
            <template v-slot:label>
                {{$t('globalTrans.from_date')}}
              </template>
              <b-form-input
                class="fromDate"
                v-model="search.from_date"
                :placeholder="$t('globalTrans.select_date')"
              ></b-form-input>
            </b-form-group>
           </b-col>
          <b-col  xs="12" sm="12" md="6" lg="6" xl="6">
            <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="to_date"
            >
              <template v-slot:label>
                {{$t('globalTrans.to_date')}}
              </template>
              <b-form-input
                  class="fromDate"
                  v-model="search.to_date"
                  :placeholder="$t('globalTrans.select_date')"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4" xl="4">
            <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row v-show="showData">
      <b-col md="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-col md="12">
                <iq-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">{{  $t('dealer_panel.fertilizer_requisition_report') }}</h4>
                  </template>
                  <template v-slot:headerAction>
                    <b-button variant="primary" @click="pdfExport" class="mr-2">
                    {{  $t('globalTrans.export_pdf') }}
                    </b-button>
                  </template>
                  <template v-slot:body>
                    <b-overlay>
                      <b-row>
                        <b-col>
                          <list-report-head :base-url="seedFertilizerServiceBaseUrl" uri="fertilizer/config/report-heading/detail" :org-id="3">
                                  {{ $t('dealer_panel.fertilizer_requisition_report') }}
                          </list-report-head>
                        </b-col>
                      </b-row>
                      <div class="text-black">
                      <b-row>
                        <b-col md="4"><strong>{{ $t('warehouse_config.fiscal_year') }}: </strong>{{ search.fiscal_year_id ? (currentLocale === 'en' ? searchResult.fiscal_year : searchResult.fiscal_year_bn) : $t('globalTrans.all')  }}</b-col>
                        <b-col md="4"><strong>{{ $t('pusti_mapping.month') }}: </strong>{{ search.month  ?  (currentLocale === 'en' ? searchResult.month : searchResult.month_bn) : $t('globalTrans.all') }}</b-col>
                        <b-col md="4"><strong>{{ $t('fertilizerSales.allocationType') }}: </strong>{{ search.allocation_type_id ?  (currentLocale === 'en' ? searchResult.allocation_type : searchResult.allocation_type_bn) : $t('globalTrans.all') }}</b-col>
                      </b-row>
                      <b-row class="my-4">
                        <b-col md="4"><strong>{{ $t('globalTrans.from_date') }}: </strong><template v-if="search.from_date">{{ search.from_date | dateFormat }}</template></b-col>
                        <b-col md="4"><strong>{{ $t('globalTrans.to_date') }}: </strong><template v-if="search.to_date">{{ search.to_date | dateFormat }}</template></b-col>
                      </b-row>
                      </div>
                      <b-row>
                        <b-col md="12" class="table-responsive">
                          <b-table-simple bordered hover small caption-top responsive>
                            <b-thead>
                              <b-tr>
                                <b-th style="width:10%" class="text-center" rowspan="2">{{ $t('globalTrans.sl_no') }}</b-th>
                                <b-th style="width:10%" class="text-center" rowspan="2">{{ $t('warehouse_config.fiscal_year') }}</b-th>
                                <b-th style="width:10%" class="text-center" rowspan="2">{{ $t('pusti_mapping.month') }}</b-th>
                                <b-th style="width:10%" class="text-center" rowspan="2">{{ $t('fertilizerSales.allocationType') }}</b-th>
                                <b-th style="width:10%" class="text-center" rowspan="2">{{ $t('pump_install.requisition_date') }}</b-th>
                                <b-th style="width:10%" class="text-center" :colspan="fertilizerNameList.length">{{ $t('dealer_panel.requisition_amount') }}</b-th>
                              </b-tr>
                              <b-tr>
                                <b-th style="width:10%" class="text-right" v-for="(fertilizer, index) in fertilizerNameList" :key="index">{{ currentLocale === 'en' ? fertilizer.text_en : fertilizer.text_bn }}</b-th>
                              </b-tr>
                            </b-thead>
                            <b-tbody>
                              <template v-if="requsitionReportData.length > 0">
                              <b-tr v-for="(item, index) in requsitionReportData" :key="index">
                                <b-td style="text-align:center">
                                  {{ $n(index + 1) }}
                                </b-td>
                                <b-td style="text-align:center">
                                  {{ currentLocale === 'en' ? item.fiscal_year : item.fiscal_year_bn }}
                                </b-td>
                                <b-td style="text-align:center">
                                  {{ currentLocale === 'en' ? item.month_name : item.month_name_bn }}
                                </b-td>
                                <b-td style="text-align:center">
                                  {{ currentLocale === 'en' ? item.allocation_type : item.allocation_type_bn }}
                                </b-td>
                                <b-td style="text-align:center">
                                  {{ item.requisition_date | dateFormat }}
                                </b-td>
                                <b-td v-for="(fertilizerName, fertilizerIndex) in fertilizerNameList" :key="index + '_' +fertilizerIndex" class="text-right">
                                  {{ item.requision_amount_obj[fertilizerName.value] ? $n(item.requision_amount_obj[fertilizerName.value]) : '' }}
                                </b-td>
                              </b-tr>
                              </template>
                              <template v-else>
                                <b-tr>
                                  <b-td :colspan="totalTableColumns" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                </b-tr>
                              </template>
                            </b-tbody>
                          </b-table-simple>
                        </b-col>
                      </b-row>
                    </b-overlay>
                  </template>
                </iq-card>
              </b-col>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { reportHeadingListfertilizer, fertilizerRequisitionReport } from '../../../../api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import ExportPdf from './export_pdf_details'
import flatpickr from 'flatpickr'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

const excelColumn = {
  SL: 'serial',
  'Fertilizer Name': 'fertilizerName',
  'Requisition Amount': 'requisitionAmount',
  'Stock Balance': 'stockBalance'
}

const excelColumnBn = {
  'ক্রমিক নং': 'serial',
  'সারের নাম': 'fertilizerName',
  'সারের চাহিদার পরিমাণ': 'requisitionAmount',
  'স্টক ব্যালেন্স': 'stockBalance'
}

export default {
  name: 'UiDataTable',
  components: {
    ListReportHead
  },
  data () {
    return {
      showData: false,
      showHeading: false,
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 1
      },
      search: {
        org_id: 3,
        fertilizer_type_id: 0,
        fertilizer_id: 0,
        requisition_date: null,
        fiscal_year_id: 0,
        month: 0,
        allocation_type_id: 0,
        from_date: '',
        to_date: ''
      },
      searchResult: {
        fiscal_year: '',
        fiscal_year_bn: '',
        month_name: '',
        month_name_bn: '',
        allocation_type: '',
        allocation_type_bn: '',
        from_date: '',
        from_date_bn: '',
        to_date: '',
        to_date_bn: ''
      },
      organization: [],
      excelData: [],
      requsitionReportData: [],
      testId: 0,
      rows: [],
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      reportHeadingListfertilizer: reportHeadingListfertilizer,
      loading: false
    }
  },
  computed: {
      json_fields: function () {
        return this.currentLocale === 'bn' ? excelColumnBn : excelColumn
      },
      dataCustomizeExcel () {
        var serial = 0
        const customizeData = this.requsitionReportData.map(item => {
          serial += 1
          if (this.currentLocale === 'bn') {
            item.serial = this.$n(serial)
            item.fertilizerName = item.fertilizer_name_bn
            item.requisitionAmount = this.$n(item.Requisition_Amount)
            item.stockBalance = this.$n(item.Stock_Balance)
          } else {
            item.serial = this.$n(serial)
            item.fertilizerName = item.fertilizer_name
            item.requisitionAmount = this.$n(item.Requisition_Amount)
            item.stockBalance = this.$n(item.Stock_Balance)
          }
          return Object.assign({}, item)
        })
        return customizeData
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('fertilizerConfig.fertilizer_name'), class: 'text-left' },
          { label: this.$t('dealer_panel.requisition_amount'), class: 'text-left' },
          { label: this.$t('dealer_panel.stock_balance'), class: 'text-left' }
        ]
      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'fertilizer_name_bn' },
          { key: 'Requisition_Amount' },
          { key: 'Stock_Balance' }
        ]
      } else {
        keys = [
           { key: 'index' },
          { key: 'fertilizer_name' },
          { key: 'Requisition_Amount' },
          { key: 'Stock_Balance' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    },
    fertilizerTypeList: function () {
        return this.$store.state.ExternalUserIrrigation.commonObj.fertilizerTypeList.filter(item => item.status === 1)
    },
    fertilizerNameList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.fertilizerNameList.filter(item => item.status === 1)
    },
    fiscalYearList: function () {
      const fiscalyear = this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.filter(item => item.status === 0)
      return fiscalyear.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    monthList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.monthListfiscalYear
    },
    allocationTypeList: function () {
      const type = this.$store.state.ExternalUserIrrigation.commonObj.allocationTypeList.filter(item => item.status === 1)
      const tempData = type.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.text_bn, value: item.value }
        } else {
          return { text: item.text_en, value: item.value }
        }
      })
      return tempData
    },
    currentLocale: function () {
        return this.$i18n.locale
    },
    totalTableColumns: function () {
      return 5 + this.fertilizerNameList.length
    }
  },
   watch: {
    // 'search.fertilizer_type_id': function (newVal, oldVal) {
    //   this.fertilizerNameList = this.getFertilizerList(newVal)
    // }
  },
  created () {
    this.loadData()
  },
  mounted () {
    flatpickr('.fromDate', {})
    core.index()
  },
  methods: {
    searchClick () {
      this.loadData()
    },
    default () {
      return {
        id: this.rows.length
      }
    },
    searchData () {
      this.loadData()
      this.showData = true
      this.showHeading = true
    },
    getFertilizerList (fertilizerTypeId) {
      const fertilizerNameList = this.$store.state.ExternalUserIrrigation.commonObj.fertilizerNameList.filter(item => item.status === 1)
      if (fertilizerTypeId) {
        return fertilizerNameList.filter(fertilizerName => fertilizerName.fertilizer_type_id === fertilizerTypeId)
      }
      return fertilizerNameList
    },
   loadData () {
      this.loading = true
      RestApi.getData(seedFertilizerServiceBaseUrl, fertilizerRequisitionReport, this.search).then(response => {
        if (response.success) {
          if (this.search.fiscal_year_id) {
            const fiscalYearObj = this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.find(fiscalYear => fiscalYear.value === this.search.fiscal_year_id)
            this.searchResult.fiscal_year = fiscalYearObj.text_en
            this.searchResult.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            this.searchResult.fiscal_year = ''
            this.searchResult.fiscal_year_bn = ''
          }
          if (this.search.month) {
            const monthObj = this.monthList.find(month => month.value === parseInt(this.search.month))
            this.searchResult.month = monthObj.text_en
            this.searchResult.month_bn = monthObj.text_bn
          } else {
            this.searchResult.month = ''
            this.searchResult.month_bn = ''
          }
          if (this.search.allocation_type_id) {
            const allocationTypeObj = this.$store.state.ExternalUserIrrigation.commonObj.allocationTypeList.find(allocationType => allocationType.value === parseInt(this.search.allocation_type_id))
            this.searchResult.allocation_type = allocationTypeObj.text_en
            this.searchResult.allocation_type_bn = allocationTypeObj.text_bn
          } else {
            this.searchResult.allocation_type = ''
            this.searchResult.allocation_type_bn = ''
          }
          this.requsitionReportData = this.getCustomizedDataList(response.data)
        }
        this.loading = false
      }).catch(error => {
        if (error) {
          //
        }
        this.requsitionReportData = []
        this.loading = false
      })
    },
    getCustomizedDataList (data) {
      const listData = data.map(item => {
        const customizedObj = {}
        const fiscalYearObj = this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.find(fiscalYear => fiscalYear.value === item.fiscal_year_id)
        const monthObj = this.monthList.find(month => month.value === parseInt(item.month))
        const allocationTypeObj = this.$store.state.ExternalUserIrrigation.commonObj.allocationTypeList.find(allocationType => allocationType.value === item.allocation_type_id)
        const requisitionAmountObj = {}
        this.fertilizerNameList.filter(fertilizerName => {
          const fertilizerAmountObj = item.dealer_requisition_details.find(fertilizerAmountItem => fertilizerAmountItem.fertilizer_id === fertilizerName.value)
          requisitionAmountObj[fertilizerName.value] = typeof fertilizerAmountObj !== 'undefined' ? fertilizerAmountObj.quantity : ''
        })
        if (typeof fiscalYearObj !== 'undefined') {
          customizedObj.fiscal_year = fiscalYearObj.text_en
          customizedObj.fiscal_year_bn = fiscalYearObj.text_bn
        } else {
          customizedObj.fiscal_year = ''
          customizedObj.fiscal_year_bn = ''
        }
        if (typeof monthObj !== 'undefined') {
          customizedObj.month_name = monthObj.text_en
          customizedObj.month_name_bn = monthObj.text_bn
        } else {
          customizedObj.month_name = ''
          customizedObj.month_name_bn = ''
        }
        if (typeof allocationTypeObj !== 'undefined') {
          customizedObj.allocation_type = allocationTypeObj.text_en
          customizedObj.allocation_type_bn = allocationTypeObj.text_bn
        } else {
          customizedObj.allocation_type = ''
          customizedObj.allocation_type_bn = ''
        }
        customizedObj.requision_amount_obj = requisitionAmountObj
        return Object.assign({}, item, customizedObj)
      })
      return listData
    },
    pdfExport () {
      const reportTitle = this.$i18n.locale === 'en' ? 'Fertilizer Requisition Report' : 'সারের চাহিদাপত্রের রিপোর্ট '
      ExportPdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, this)
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
</style>
